{
  "default": {
    "query": {
      "q": "",
      "filters": {},
      "resultTypes": {
        "taxon_id": {
          "facet.field": "{!facet.limit='50' facet.mincount='0' key='taxon_id'}taxon_id"
        }
      },
      "cachedResultTypes": {}
    },
    "response": {
      "validation": { "valid": true },
      "obj":{
        "responseHeader": {
          "status": 0,
          "QTime": 0,
          "params": {
            "facet": "true",
            "q": "*",
            "wt": "json",
            "rows": "0"
          }
        },
        "response": {
          "numFound": 1666975,
          "start": 0,
          "docs": []
        },
        "facet_counts": {
          "facet_queries": {},
          "facet_fields": {},
          "facet_dates": {},
          "facet_ranges": {},
          "facet_intervals": {},
          "facet_heatmaps": {}
        }
      }
    }
  },
  "faceted": {
    "query": {
      "q": "",
      "filters": {
        "_terms:(PAD4)": {}
      },
      "resultTypes": {
        "taxon_id": {
          "facet.field": "{!facet.limit='50' facet.mincount='0' key='taxon_id'}taxon_id"
        }
      },
      "cachedResultTypes": {}
    },
    "response": {
      "validation": { "valid": true },
      "obj":{
        "responseHeader": {
          "status": 0,
          "QTime": 2,
          "params": {
            "facet": "true",
            "q": "*",
            "facet.field": "{!facet.limit='50' facet.mincount='0' key='taxon_id'}taxon_id",
            "fq": "_terms:(PAD4)",
            "rows": "0"
          }
        },
        "response": {
          "numFound": 5,
          "start": 0,
          "maxScore": 1.0,
          "docs": []
        },
        "facet_counts": {
          "facet_queries": {},
          "facet_fields": {
            "taxon_id": [
              "3702",
              1,
              "3760",
              1,
              "4113",
              1,
              "29760",
              1,
              "81972",
              1,
              "3055",
              0,
              "3218",
              0,
              "3641",
              0,
              "3694",
              0,
              "3847",
              0,
              "3880",
              0,
              "4081",
              0,
              "4528",
              0,
              "4529",
              0,
              "4533",
              0,
              "4536",
              0,
              "4537",
              0,
              "4538",
              0,
              "4555",
              0,
              "4558",
              0,
              "4565",
              0,
              "4572",
              0,
              "4577",
              0,
              "13333",
              0,
              "15368",
              0,
              "37682",
              0,
              "39946",
              0,
              "39947",
              0,
              "40148",
              0,
              "40149",
              0,
              "51351",
              0,
              "65489",
              0,
              "77586",
              0,
              "88036",
              0,
              "109376",
              0,
              "112509",
              0,
              "214687",
              0,
              "280699",
              0,
              "436017",
              0
            ]
          },
          "facet_dates": {},
          "facet_ranges": {},
          "facet_intervals": {},
          "facet_heatmaps": {}
        }
      }
    }
  },
  "rows10": {
    "query": {
      "q": "",
      "filters": {
        "_terms:(PAD4)": {}
      },
      "resultTypes": {
        "list": {
          "rows": 10,
          "start": 0
        },
        "fixed_200__bin": {
          "facet.field": "{!facet.limit='-1' facet.mincount='1' key='fixed_200__bin'}fixed_200__bin"
        }
      },
      "count": 5,
      "cachedResultTypes": {}
    },
    "response": {
      "validation": { "valid": true },
      "obj":{
        "responseHeader": {
          "status": 0,
          "QTime": 97,
          "params": {
            "facet": "true",
            "start": "0",
            "q": "*",
            "facet.field": "{!facet.limit='-1' facet.mincount='1' key='fixed_200__bin'}fixed_200__bin",
            "fq": "_terms:(PAD4)",
            "rows": "10"
          }
        },
        "response": {
          "numFound": 5,
          "start": 0,
          "maxScore": 1.0,
          "docs": [
            {
              "id": "PRUPE_ppa025554mg",
              "name": "PAD4",
              "description": "alpha/beta-Hydrolases superfamily protein",
              "taxon_id": 3760,
              "biotype": "protein_coding",
              "capabilities": [
                "location",
                "homology",
                "xrefs",
                "GO",
                "domains",
                "taxonomy"
              ],
              "closest_rep_id": "MTR_8g494200",
              "closest_rep_taxon_id": 3880,
              "closest_rep_description": "phytoalexin deficient protein",
              "model_rep_id": "AT3G52430",
              "model_rep_taxon_id": 3702,
              "model_rep_name": "PAD4",
              "model_rep_description": "Lipase-like PAD4",
              "score": 1
            },
            {
              "id": "PGSC0003DMG400019873",
              "name": "PAD4",
              "description": "Phytoalexin-deficient 4-2 protein",
              "taxon_id": 4113,
              "biotype": "protein_coding",
              "capabilities": [
                "location",
                "homology",
                "xrefs",
                "GO",
                "domains",
                "taxonomy"
              ],
              "score": 1
            },
            {
              "id": "AT3G52430",
              "name": "PAD4",
              "description": "Lipase-like PAD4",
              "taxon_id": 3702,
              "biotype": "protein_coding",
              "capabilities": [
                "location",
                "homology",
                "xrefs",
                "GO",
                "PO",
                "domains",
                "taxonomy"
              ],
              "model_rep_id": "AT3G52430",
              "model_rep_taxon_id": 3702,
              "model_rep_name": "PAD4",
              "model_rep_description": "Lipase-like PAD4",
              "score": 1
            },
            {
              "id": "scaffold_502313.1",
              "name": "PAD4",
              "description": "Phytoalexin deficient 4",
              "taxon_id": 81972,
              "biotype": "protein_coding",
              "capabilities": [
                "location",
                "homology",
                "xrefs",
                "GO",
                "domains",
                "taxonomy"
              ],
              "score": 1
            },
            {
              "id": "VIT_07s0031g02390",
              "name": "PAD4",
              "description": "Putative uncharacterized protein",
              "taxon_id": 29760,
              "biotype": "protein_coding",
              "capabilities": [
                "location",
                "homology",
                "xrefs",
                "GO",
                "domains",
                "taxonomy"
              ],
              "closest_rep_id": "AT3G52430",
              "closest_rep_taxon_id": 3702,
              "closest_rep_name": "PAD4",
              "closest_rep_description": "Lipase-like PAD4",
              "score": 1
            }
          ]
        },
        "facet_counts": {
          "facet_queries": {},
          "facet_fields": {
            "fixed_200__bin": [
              "516",
              1,
              "602",
              1,
              "1231",
              1,
              "3476",
              1,
              "4927",
              1
            ]
          },
          "facet_dates": {},
          "facet_ranges": {},
          "facet_intervals": {},
          "facet_heatmaps": {}
        }
      }
    }
  }
}
