{
  "obj": [
    {"_id":"GCA_000091205.1","db":"cyanidioschyzon_merolae_core_48_82_1","taxon_id":280699,"system_name":"cyanidioschyzon_merolae","type":"genome","length":16728945,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","Mito","Chloro"],"lengths":[422616,457013,481791,513455,528682,536163,584452,739753,810151,839707,852849,859119,866983,852727,902900,908485,1232258,1253087,1282939,1621617,32211,149987]},"num_genes":5106}
  ,
    {"_id":"GCA_000001735.1","db":"arabidopsis_thaliana_core_48_82_10","taxon_id":3702,"system_name":"arabidopsis_thaliana","type":"genome","length":119667750,"regions":{"names":["1","2","3","4","5","Mt","Pt"],"lengths":[30427671,19698289,23459830,18585056,26975502,366924,154478]},"num_genes":33602}
  ,
    {"_id":"GCA_000005505.1","db":"brachypodium_distachyon_core_48_82_12","taxon_id":15368,"system_name":"brachypodium_distachyon","type":"genome","length":271148425,"regions":{"names":["1","2","3","4","5","UNANCHORED"],"lengths":[74834646,59328898,59892396,48648102,28444383,774881]},"num_genes":26552}
  ,
    {"_id":"GCA_000092065.1","db":"ostreococcus_lucimarinus_core_48_82_1","taxon_id":436017,"system_name":"ostreococcus_lucimarinus","type":"genome","length":13204888,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21"],"lengths":[1152508,895087,982987,930724,847696,818664,783246,701771,670853,613585,593542,538963,528469,708927,468366,428333,366173,149386,154676,549133,321799]},"num_genes":7640}
  ,
    {"_id":"GCA_000338895.2","db":"oryza_meridionalis_core_48_82_13","taxon_id":40149,"system_name":"oryza_meridionalis","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[335668232]},"num_genes":31591}
  ,
    {"_id":"GCA_000003745.2","db":"vitis_vinifera_core_48_82_3","taxon_id":29760,"system_name":"vitis_vinifera","type":"genome","length":426176009,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","UNANCHORED"],"lengths":[23037639,18779844,19341862,23867706,25021643,21508407,21026613,22385789,23006712,18140952,19818926,22702307,24396255,30274277,20304914,22053297,17126926,29360087,24021853,60089413]},"num_genes":29971}
  ,
    {"_id":"GCA_000576495.1","db":"oryza_glumaepatula_core_48_82_15","taxon_id":40148,"system_name":"oryza_glumaepatula","type":"genome","length":372860283,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[46529941,38039368,37594838,32884216,30429317,31548187,28350858,27185770,23490084,22967634,27098305,26741765]},"num_genes":39018}
  ,
    {"_id":"GCA_000005425.2","db":"oryza_sativa_core_48_82_7","taxon_id":39947,"system_name":"oryza_sativa","type":"genome","length":373245519,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[43270923,35937250,36413819,35502694,29958434,31248787,29697621,28443022,23012720,23207287,29021106,27531856,1178721]},"num_genes":91080}
  ,
    {"_id":"PRJEB4137","db":"oryza_rufipogon_core_48_82_11","taxon_id":4529,"system_name":"oryza_rufipogon","type":"genome","length":338040714,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[39866532,33962743,34446443,30521686,26652778,27870862,26200591,25958679,20482102,20731201,27785585,23561512]},"num_genes":39799}
  ,
    {"_id":"GCA_000576065.1","db":"oryza_nivara_core_48_82_10","taxon_id":4536,"system_name":"oryza_nivara","type":"genome","length":337950324,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[42845077,35065507,36134596,28646061,28014461,29411429,24717764,26703665,20407407,21549876,24378308,20076173]},"num_genes":37985}
  ,
    {"_id":"GCA_000325765.3","db":"leersia_perrieri_core_48_82_14","taxon_id":77586,"system_name":"leersia_perrieri","type":"genome","length":266687832,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[32922458,26406337,29602667,23326293,20807039,21016138,22540073,20165712,15534364,15557830,21046960,17761961]},"num_genes":30615}
  ,
    {"_id":"GCA_000002595.2","db":"chlamydomonas_reinhardtii_core_48_82_1","taxon_id":3055,"system_name":"chlamydomonas_reinhardtii","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[120404952]},"num_genes":14487}
  ,
    {"_id":"GCA_000313855.1","db":"musa_acuminata_core_48_82_1","taxon_id":214687,"system_name":"musa_acuminata","type":"genome","length":331812599,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","UNANCHORED"],"lengths":[27573629,22054697,30470407,30051516,29377369,34899179,28617404,35439739,34148863,33665772,25514024,141147818]},"num_genes":37579}
  ,
    {"_id":"GCA_000573905.1","db":"oryza_punctata_core_48_82_12","taxon_id":4537,"system_name":"oryza_punctata","type":"genome","length":393816603,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[46096743,39559433,38925377,33711903,31082981,34615992,31244610,29853973,26294017,25992119,28494620,27944835]},"num_genes":36957}
  ,
    {"_id":"GCA_000182155.2","db":"oryza_barthii_core_48_82_3","taxon_id":65489,"system_name":"oryza_barthii","type":"genome","length":308272304,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12"],"lengths":[36915442,31686972,33311619,27574323,24206129,25711811,24128185,22678724,19219615,19274048,23014695,20550741]},"num_genes":36899}
  ,
    {"_id":"GCA_000346465.1","db":"prunus_persica_core_48_82_1","taxon_id":3760,"system_name":"prunus_persica","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[227251827]},"num_genes":29499}
  ,
    {"_id":"GCA_000143415.1","db":"selaginella_moellendorffii_core_48_82_1","taxon_id":88036,"system_name":"selaginella_moellendorffii","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[212645483]},"num_genes":34888}
  ,
    {"_id":"GCA_000226075.1","db":"solanum_tuberosum_core_48_82_4","taxon_id":4113,"system_name":"solanum_tuberosum","type":"genome","length":724917384,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[88663952,48614681,62190286,72208621,52070158,59532096,56760843,56938457,61540751,59756223,45475667,61165649,85736662]},"num_genes":42974}
  ,
    {"_id":"GCA_000263155.1","db":"setaria_italica_core_48_82_21","taxon_id":4555,"system_name":"setaria_italica","type":"genome","length":401300876,"regions":{"names":["1","2","3","4","5","6","7","8","9","UNANCHORED"],"lengths":[42145699,49200776,50652576,40408058,47253416,36015257,35964515,40690061,58970518,4436465]},"num_genes":35471}
  ,
    {"_id":"GCA_000005005.5","db":"zea_mays_core_48_82_6","taxon_id":4577,"system_name":"zea_mays","type":"genome","length":2060653601,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","Pt","Mt","UNANCHORED"],"lengths":[301476924,237917468,232245527,242062272,217959525,169407836,176826311,175377492,157038028,149632204,140384,569630,7210561]},"num_genes":39625}
  ,
    {"_id":"GCA_000219495.2","db":"medicago_truncatula_core_48_82_2","taxon_id":3880,"system_name":"medicago_truncatula","type":"genome","length":384466993,"regions":{"names":["1","2","3","4","5","6","7","8","UNANCHORED"],"lengths":[52991155,45729672,55515152,56582383,43630510,35275713,49172423,45569985,28333398]},"num_genes":54073}
  ,
    {"_id":"GCA_000403535.1","db":"theobroma_cacao_core_48_82_1","taxon_id":3641,"system_name":"theobroma_cacao","type":"genome","length":330456197,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","UNANCHORED"],"lengths":[38988864,42436413,34397752,33492547,40442896,27290986,24379470,21543242,42035188,25448839,15537478]},"num_genes":29188}
  ,
    {"_id":"GCA_000004515.1","db":"glycine_max_core_48_82_1","taxon_id":3847,"system_name":"glycine_max","type":"genome","length":950068807,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","UNANCHORED"],"lengths":[55915595,51656713,47781076,49243852,41936504,50722821,44683157,46995532,46843750,50969635,39172790,40113140,44408971,49711204,50939160,37397385,41906774,62308140,50589441,46773167,23275573]},"num_genes":54174}
  ,
    {"_id":"GCA_000004255.1","db":"arabidopsis_lyrata_core_48_82_10","taxon_id":81972,"system_name":"arabidopsis_lyrata","type":"genome","length":194182311,"regions":{"names":["1","2","3","4","5","6","7","8","UNANCHORED"],"lengths":[33132539,19320864,24464547,23328337,21221946,25113588,24649197,22951293,12485624]},"num_genes":32667}
  ,
    {"_id":"GCA_000147395.1","db":"oryza_glaberrima_core_48_82_2","taxon_id":4538,"system_name":"oryza_glaberrima","type":"genome","length":285037524,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[32613412,29142869,33053699,26299011,23192814,24174485,21799424,20292731,17607432,16910673,20796451,19154523,31382050]},"num_genes":73878}
  ,
    {"_id":"GCA_000188115.2","db":"solanum_lycopersicum_core_48_82_250","taxon_id":4081,"system_name":"solanum_lycopersicum","type":"genome","length":802138220,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[98543444,55340444,70787664,66470942,65875088,49751636,68045021,65866657,72482091,65527505,56302525,67145203,21492721]},"num_genes":38735}
  ,
    {"_id":"GCA_000002425.1","db":"physcomitrella_patens_core_48_82_11","taxon_id":3218,"system_name":"physcomitrella_patens","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[479985347]},"num_genes":32273}
  ,
    {"_id":"GCA_000003195.1","db":"sorghum_bicolor_core_48_82_14","taxon_id":4558,"system_name":"sorghum_bicolor","type":"genome","length":659229367,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","UNANCHORED"],"lengths":[73840631,77932606,74441160,68034345,62352331,62208784,64342021,55460251,59635592,60981646,79311565]},"num_genes":34567}
  ,
    {"_id":"GCA_000471905.1","db":"amborella_trichopoda_core_48_82_1","taxon_id":13333,"system_name":"amborella_trichopoda","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[706332640]},"num_genes":28721}
  ,
    {"_id":"GCA_000002775.2","db":"populus_trichocarpa_core_48_82_20","taxon_id":3694,"system_name":"populus_trichocarpa","type":"genome","length":378545895,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","UNANCHORED"],"lengths":[48367220,23562801,20216605,23188140,25802683,26894541,15101417,18835763,12942059,21538349,18885544,14929429,15658869,17716633,15134944,14134809,14661173,14966190,16008726,38592049]},"num_genes":41377}
  ,
    {"_id":"GCA_000231095.2","db":"oryza_brachyantha_core_48_82_14","taxon_id":4533,"system_name":"oryza_brachyantha","type":"genome","length":250927218,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[33916305,27085147,29620734,21479432,20131956,21794218,18603524,18224760,14107269,14643570,16001410,15318893,9910950]},"num_genes":34155}
  ,
    {"_id":"GCA_000695525.1","db":"brassica_oleracea_core_48_82_1","taxon_id":109376,"system_name":"brassica_oleracea","type":"genome","length":446885882,"regions":{"names":["C1","C2","C3","C4","C5","C6","C7","C8","C9","UNANCHORED"],"lengths":[43764888,52886895,64984695,53719093,46902585,39822476,48366697,41758685,54679868,41736625]},"num_genes":59225}
  ,
    {"_id":"GCA_000004655.2","db":"oryza_indica_core_48_82_2","taxon_id":39946,"system_name":"oryza_indica","type":"genome","length":374545499,"regions":{"names":["1","2","3","4","5","6","7","8","9","10","11","12","UNANCHORED"],"lengths":[47283185,38103930,41884883,34718618,31240961,32913967,27957088,30396518,21757032,22204031,23035369,23049917,52459391]},"num_genes":88438}
  ,
    {"_id":"GCA_000326085.1","db":"hordeum_vulgare_core_48_82_2","taxon_id":112509,"system_name":"hordeum_vulgare","type":"genome","length":3902943035,"regions":{"names":["1","2","3","4","5","6","7","Pt","UNANCHORED"],"lengths":[464124043,628342783,564427874,544168226,561411686,538755036,601597413,115974,142357816]},"num_genes":26067}
  ,
    {"_id":"v0117-2013Aug","db":"oryza_longistaminata_core_48_82_117","taxon_id":4528,"system_name":"oryza_longistaminata","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[344631721]},"num_genes":29974}
  ,
    {"_id":"GCA_000309985.1","db":"brassica_rapa_core_48_82_1","taxon_id":51351,"system_name":"brassica_rapa","type":"genome","length":256240462,"regions":{"names":["A01","A02","A03","A04","A05","A06","A07","A08","A09","A10","UNANCHORED"],"lengths":[28605136,27846329,31715688,18965343,23939834,26271742,22586724,21594650,37120481,17594535,27582321]},"num_genes":42846}
  ,
    {"_id":"GCA_000347335.1","db":"aegilops_tauschii_core_48_82_1","taxon_id":37682,"system_name":"aegilops_tauschii","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[3313764331]},"num_genes":37035}
  ,
    {"_id":"IWGSC1.0+popseq","db":"triticum_aestivum_core_48_82_2","taxon_id":4565,"system_name":"triticum_aestivum","type":"genome","length":5012504396,"regions":{"names":["1A","1B","1D","2A","2B","2D","3A","3B","3D","4A","4B","4D","5A","5B","5D","6A","6B","6D","7A","7B","7D","Mt","Pt","UNANCHORED"],"lengths":[248437066,295113561,135633378,254944609,345274828,150381694,185052605,774434471,124322642,215863839,317344599,121193423,148252673,273623383,161539237,207910053,204046169,177000238,182007620,251517909,238042887,452528,114984,1470784488]},"num_genes":112496}
  ,
    {"_id":"GCA_000347455.1","db":"triticum_urartu_core_48_82_1","taxon_id":4572,"system_name":"triticum_urartu","type":"genome","length":0,"regions":{"names":["UNANCHORED"],"lengths":[3747163292]},"num_genes":37604}
  ]
}