{
    "obj": [
        {
            "_id": 1,
            "ancestors": [
                1
            ],
            "id": "NCBITaxon:1",
            "name": "root",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1568831,
            "parents": [],
            "synonym": [
                "all"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 2759,
            "ancestors": [
                1,
                2759,
                131567
            ],
            "id": "NCBITaxon:2759",
            "is_a": [
                131567
            ],
            "name": "Eukaryota",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1568831,
            "parents": [
                "131567"
            ],
            "property_value": "has_rank NCBITaxon:superkingdom",
            "synonym": [
                "Eucarya",
                "Eucaryotae",
                "eucaryotes",
                "Eukarya",
                "Eukaryotae",
                "eukaryotes",
                "eukaryotes"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:23020233"
            ]
        },
        {
            "_id": 2763,
            "ancestors": [
                1,
                2759,
                2763,
                131567
            ],
            "id": "NCBITaxon:2763",
            "is_a": [
                2759
            ],
            "name": "Rhodophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "2759"
            ],
            "synonym": [
                "algae",
                "red algae",
                "red algae",
                "Rhodophyceae",
                "Rhodophycota",
                "rhodophytes"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 2797,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                131567
            ],
            "id": "NCBITaxon:2797",
            "is_a": [
                2763
            ],
            "name": "Bangiophyceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "2763"
            ],
            "property_value": "has_rank NCBITaxon:class",
            "synonym": [
                "Bangiophycideae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3041,
            "ancestors": [
                1,
                2759,
                3041,
                33090,
                131567
            ],
            "id": "NCBITaxon:3041",
            "is_a": [
                33090
            ],
            "name": "Chlorophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 22127,
            "parents": [
                "33090"
            ],
            "property_value": "has_rank NCBITaxon:phylum",
            "synonym": [
                "algae",
                "Chlorophycota",
                "Chlorophyta sensu Bremer 1985",
                "green algae",
                "green algae"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:23020233"
            ]
        },
        {
            "_id": 3042,
            "alt_id": [
                167765,
                3069
            ],
            "ancestors": [
                1,
                2759,
                3041,
                3042,
                3166,
                33090,
                131567
            ],
            "id": "NCBITaxon:3042",
            "is_a": [
                3166
            ],
            "name": "Chlamydomonadales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 14487,
            "parents": [
                "3166"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Chlorococcales",
                "Volvocales",
                "Volvocida"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3051,
            "ancestors": [
                1,
                2759,
                3041,
                3042,
                3051,
                3166,
                33090,
                131567
            ],
            "id": "NCBITaxon:3051",
            "is_a": [
                3042
            ],
            "name": "Chlamydomonadaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 14487,
            "parents": [
                "3042"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3052,
            "ancestors": [
                1,
                2759,
                3041,
                3042,
                3051,
                3052,
                3166,
                33090,
                131567
            ],
            "id": "NCBITaxon:3052",
            "is_a": [
                3051
            ],
            "name": "Chlamydomonas",
            "namespace": "ncbi_taxonomy",
            "num_genes": 14487,
            "parents": [
                "3051"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3055,
            "alt_id": [
                3058,
                61248
            ],
            "ancestors": [
                1,
                2759,
                3041,
                3042,
                3051,
                3052,
                3055,
                3166,
                33090,
                131567
            ],
            "id": "NCBITaxon:3055",
            "is_a": [
                3052
            ],
            "name": "Chlamydomonas reinhardtii",
            "namespace": "ncbi_taxonomy",
            "num_genes": 14487,
            "parents": [
                "3052"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Chlamydomonas reihhardtii",
                "Chlamydomonas smithii",
                "Chlamydonas reinhardtii"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:12355262"
            ]
        },
        {
            "_id": 3152,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                33090,
                131567
            ],
            "id": "NCBITaxon:3152",
            "is_a": [
                3041
            ],
            "name": "prasinophytes",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "3041"
            ],
            "synonym": [
                "Micromonadophyceae",
                "Prasinomonadida",
                "Prasinophyceae",
                "Prasinophyta"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3166,
            "ancestors": [
                1,
                2759,
                3041,
                3166,
                33090,
                131567
            ],
            "id": "NCBITaxon:3166",
            "is_a": [
                3041
            ],
            "name": "Chlorophyceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 14487,
            "parents": [
                "3041"
            ],
            "property_value": "has_rank NCBITaxon:class",
            "xref": [
                "GC_ID:1",
                "PMID:23020233"
            ]
        },
        {
            "_id": 3193,
            "ancestors": [
                1,
                2759,
                3193,
                33090,
                35493,
                131221,
                131567
            ],
            "id": "NCBITaxon:3193",
            "is_a": [
                131221
            ],
            "name": "Embryophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1541598,
            "parents": [
                "131221"
            ],
            "synonym": [
                "higher plants",
                "land plants",
                "land plants",
                "plants"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3208,
            "alt_id": [
                3194
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                33090,
                35493,
                131221,
                131567
            ],
            "id": "NCBITaxon:3208",
            "is_a": [
                3193
            ],
            "name": "Bryophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3193"
            ],
            "synonym": [
                "bryophytes",
                "Bryopsida",
                "mosses",
                "mosses",
                "Musci"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3214,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                33090,
                35493,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:3214",
            "is_a": [
                404260
            ],
            "name": "Bryopsida",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "404260"
            ],
            "property_value": "has_rank NCBITaxon:class",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3215,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                3215,
                33090,
                35493,
                114656,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:3215",
            "is_a": [
                114656
            ],
            "name": "Funariales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "114656"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3216,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                3215,
                3216,
                33090,
                35493,
                114656,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:3216",
            "is_a": [
                3215
            ],
            "name": "Funariaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3215"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3217,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                3215,
                3216,
                3217,
                33090,
                35493,
                114656,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:3217",
            "is_a": [
                3216
            ],
            "name": "Physcomitrella",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3216"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1",
                "PMID:25015729"
            ]
        },
        {
            "_id": 3218,
            "alt_id": [
                1130753,
                145481
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                3215,
                3216,
                3217,
                3218,
                33090,
                35493,
                114656,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:3218",
            "is_a": [
                3217
            ],
            "name": "Physcomitrella patens",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3217"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Physcomitrella patens subsp. patens",
                "Physcomitrium patens"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:25015729"
            ]
        },
        {
            "_id": 3244,
            "ancestors": [
                1,
                2759,
                3193,
                3244,
                33090,
                35493,
                58023,
                131221,
                131567,
                1521260
            ],
            "id": "NCBITaxon:3244",
            "is_a": [
                1521260
            ],
            "name": "Selaginellales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34888,
            "parents": [
                "1521260"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "spike mosses"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3245,
            "ancestors": [
                1,
                2759,
                3193,
                3244,
                3245,
                33090,
                35493,
                58023,
                131221,
                131567,
                1521260
            ],
            "id": "NCBITaxon:3245",
            "is_a": [
                3244
            ],
            "name": "Selaginellaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34888,
            "parents": [
                "3244"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "spike moss family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3246,
            "ancestors": [
                1,
                2759,
                3193,
                3244,
                3245,
                3246,
                33090,
                35493,
                58023,
                131221,
                131567,
                1521260
            ],
            "id": "NCBITaxon:3246",
            "is_a": [
                3245
            ],
            "name": "Selaginella",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34888,
            "parents": [
                "3245"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3398,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567
            ],
            "id": "NCBITaxon:3398",
            "is_a": [
                58024
            ],
            "name": "Magnoliophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1474437,
            "parents": [
                "58024"
            ],
            "synonym": [
                "Angiospermae",
                "angiosperms",
                "flowering plants",
                "flowering plants"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3602,
            "alt_id": [
                39206
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3602,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91834,
                131221,
                131567,
                403667,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3602",
            "is_a": [
                403667
            ],
            "name": "Vitaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29971,
            "parents": [
                "403667"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "grape family",
                "Vitidaceae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3603,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3602,
                3603,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91834,
                131221,
                131567,
                403667,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3603",
            "is_a": [
                3602
            ],
            "name": "Vitis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29971,
            "parents": [
                "3602"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3629,
            "alt_id": [
                3639,
                45192,
                45323
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3629,
                33090,
                35493,
                41938,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3629",
            "is_a": [
                41938
            ],
            "name": "Malvaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29188,
            "parents": [
                "41938"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "mallow family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3640,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3629,
                3640,
                33090,
                35493,
                41938,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                214909,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3640",
            "is_a": [
                214909
            ],
            "name": "Theobroma",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29188,
            "parents": [
                "214909"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3641,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3629,
                3640,
                3641,
                33090,
                35493,
                41938,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                214909,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3641",
            "is_a": [
                3640
            ],
            "name": "Theobroma cacao",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29188,
            "parents": [
                "3640"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "cacao",
                "chocolate",
                "cocoa"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3646,
            "alt_id": [
                3976,
                40028,
                4003,
                4333,
                4385
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3646,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3646",
            "is_a": [
                91835
            ],
            "name": "Malpighiales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 41377,
            "parents": [
                "91835"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Violales",
                "Violanae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3688,
            "alt_id": [
                124995,
                45162
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3646,
                3688,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3688",
            "is_a": [
                3646
            ],
            "name": "Salicaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 41377,
            "parents": [
                "3646"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "Flacourtiaceae",
                "willow family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3689,
            "alt_id": [
                36505
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3646,
                3688,
                3689,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                238069,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3689",
            "is_a": [
                238069
            ],
            "name": "Populus",
            "namespace": "ncbi_taxonomy",
            "num_genes": 41377,
            "parents": [
                "238069"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "poplar",
                "poplar trees",
                "poplars"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3694,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3646,
                3688,
                3689,
                3694,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                238069,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3694",
            "is_a": [
                3689
            ],
            "name": "Populus trichocarpa",
            "namespace": "ncbi_taxonomy",
            "num_genes": 41377,
            "parents": [
                "3689"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "black cottonwood",
                "Populus balsamifera subsp. trichocarpa",
                "western balsam poplar"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3699,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3699",
            "is_a": [
                91836
            ],
            "name": "Brassicales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 168340,
            "parents": [
                "91836"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Capparales"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3700,
            "alt_id": [
                25773
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3700",
            "is_a": [
                3699
            ],
            "name": "Brassicaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 168340,
            "parents": [
                "3699"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "Cruciferae",
                "mustard family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3701,
            "alt_id": [
                45250
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3701,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                980083,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3701",
            "is_a": [
                980083
            ],
            "name": "Arabidopsis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 66269,
            "parents": [
                "980083"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "Cardaminopsis"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3702,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3701,
                3702,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                980083,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3702",
            "is_a": [
                3701
            ],
            "name": "Arabidopsis thaliana",
            "namespace": "ncbi_taxonomy",
            "num_genes": 33602,
            "parents": [
                "3701"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Arabidopsis thaliana (thale cress)",
                "Arbisopsis thaliana",
                "mouse-ear cress",
                "thale cress",
                "thale-cress"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3705,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3705,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3705",
            "is_a": [
                981071
            ],
            "name": "Brassica",
            "namespace": "ncbi_taxonomy",
            "num_genes": 102071,
            "parents": [
                "981071"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3711,
            "alt_id": [
                3706
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3705,
                3711,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3711",
            "is_a": [
                3705
            ],
            "name": "Brassica rapa",
            "namespace": "ncbi_taxonomy",
            "num_genes": 42846,
            "parents": [
                "3705"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "field mustard"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3712,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3705,
                3712,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3712",
            "is_a": [
                3705
            ],
            "name": "Brassica oleracea",
            "namespace": "ncbi_taxonomy",
            "num_genes": 59225,
            "parents": [
                "3705"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3744,
            "alt_id": [
                3473,
                3601,
                4266
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3744",
            "is_a": [
                91835
            ],
            "name": "Rosales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "91835"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3745,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                3745,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3745",
            "is_a": [
                3744
            ],
            "name": "Rosaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "3744"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "Malaceae",
                "rose family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3754,
            "alt_id": [
                140650,
                203800
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                3745,
                3754,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                171637,
                721805,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3754",
            "is_a": [
                721805
            ],
            "name": "Prunus",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "721805"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3760,
            "alt_id": [
                203801
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                3745,
                3754,
                3760,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                171637,
                721805,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3760",
            "is_a": [
                3754
            ],
            "name": "Prunus persica",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "3754"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Amygdalus persica",
                "peach",
                "Persica vulgaris",
                "Prunus persica var densa"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3803,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3803",
            "is_a": [
                72025
            ],
            "name": "Fabaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 108247,
            "parents": [
                "72025"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "Leguminosae",
                "pea family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3814,
            "alt_id": [
                24958
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3814",
            "is_a": [
                3803
            ],
            "name": "Papilionoideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 108247,
            "parents": [
                "3803"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "synonym": [
                "Faboideae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3846,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                3846,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163735,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3846",
            "is_a": [
                163735
            ],
            "name": "Glycine",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54174,
            "parents": [
                "163735"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3847,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                3846,
                3847,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163735,
                1437183,
                1437201,
                1462606
            ],
            "id": "NCBITaxon:3847",
            "is_a": [
                1462606
            ],
            "name": "Glycine max",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54174,
            "parents": [
                "1462606"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Glycine max; cv. Wye",
                "soybean",
                "soybeans"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3877,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                3877,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163742,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3877",
            "is_a": [
                163742
            ],
            "name": "Medicago",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54073,
            "parents": [
                "163742"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "medics"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 3880,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                3877,
                3880,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163742,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:3880",
            "is_a": [
                3877
            ],
            "name": "Medicago truncatula",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54073,
            "parents": [
                "3877"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "barrel medic"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4069,
            "alt_id": [
                41936
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:4069",
            "is_a": [
                91888
            ],
            "name": "Solanales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "91888"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Solananae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4070,
            "alt_id": [
                40319
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:4070",
            "is_a": [
                4069
            ],
            "name": "Solanaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "4069"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "nightshade family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4081,
            "alt_id": [
                195582
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                4081,
                4107,
                33090,
                35493,
                49274,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                424574,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:4081",
            "is_a": [
                49274
            ],
            "name": "Solanum lycopersicum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 38735,
            "parents": [
                "49274"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Lycopersicon esculentum",
                "Lycopersicon esculentum var. esculentum",
                "Lycopersicon lycopersicum",
                "Lycopersicum esculentum",
                "Solanum esculentum",
                "Solanum lycopersicon",
                "tomato"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4107,
            "alt_id": [
                4080,
                49275
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                4107,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                424574,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:4107",
            "is_a": [
                424574
            ],
            "name": "Solanum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "424574"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4113,
            "alt_id": [
                90692
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                4107,
                4113,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                424574,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:4113",
            "is_a": [
                4107
            ],
            "name": "Solanum tuberosum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 42974,
            "parents": [
                "4107"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "potato",
                "potatoes",
                "Solanum tuberosum subsp. tuberosum"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4447,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183
            ],
            "id": "NCBITaxon:4447",
            "is_a": [
                1437183
            ],
            "name": "Liliopsida",
            "namespace": "ncbi_taxonomy",
            "num_genes": 957385,
            "parents": [
                "1437183"
            ],
            "property_value": "has_rank NCBITaxon:class",
            "synonym": [
                "monocots",
                "monocots",
                "Monocotyledoneae",
                "monocotyledons"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4479,
            "alt_id": [
                1661618
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4479",
            "is_a": [
                38820
            ],
            "name": "Poaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 919806,
            "parents": [
                "38820"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "Bambusaceae",
                "Gramineae",
                "grass family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4480,
            "alt_id": [
                37875,
                58864,
                72405
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4480,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:4480",
            "is_a": [
                1648030
            ],
            "name": "Aegilops",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37035,
            "parents": [
                "1648030"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "Aegilops L."
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4512,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4512,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648017,
                1648038
            ],
            "id": "NCBITaxon:4512",
            "is_a": [
                1648017
            ],
            "name": "Hordeum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26067,
            "parents": [
                "1648017"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "barleys"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4513,
            "alt_id": [
                36528
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4512,
                4513,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648017,
                1648038
            ],
            "id": "NCBITaxon:4513",
            "is_a": [
                4512
            ],
            "name": "Hordeum vulgare",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26067,
            "parents": [
                "4512"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "barley",
                "Horedum vulgare"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4527,
            "alt_id": [
                77587
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4527",
            "is_a": [
                1648021
            ],
            "name": "Oryza",
            "namespace": "ncbi_taxonomy",
            "num_genes": 539774,
            "parents": [
                "1648021"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4528,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4528,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4528",
            "is_a": [
                4527
            ],
            "name": "Oryza longistaminata",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29974,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "long-staminate rice",
                "red rice"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4529,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4529,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4529",
            "is_a": [
                4527
            ],
            "name": "Oryza rufipogon",
            "namespace": "ncbi_taxonomy",
            "num_genes": 39799,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "common wild rice",
                "red rice"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:15654106",
                "PMID:17617555",
                "PMID:17907810"
            ]
        },
        {
            "_id": 4530,
            "alt_id": [
                389215
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4530,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4530",
            "is_a": [
                4527
            ],
            "name": "Oryza sativa",
            "namespace": "ncbi_taxonomy",
            "num_genes": 179518,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "red rice",
                "rice"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:15654106",
                "PMID:17617555",
                "PMID:17907810",
                "PMID:19706531"
            ]
        },
        {
            "_id": 4533,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4533,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4533",
            "is_a": [
                4527
            ],
            "name": "Oryza brachyantha",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34155,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "malo sina"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4536,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4536,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4536",
            "is_a": [
                4527
            ],
            "name": "Oryza nivara",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37985,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Oryza nivarra",
                "Oryza sativa f. spontanea",
                "Oryza sativa f. spontanea Roshev. nom. illeg."
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4537,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4537,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4537",
            "is_a": [
                4527
            ],
            "name": "Oryza punctata",
            "namespace": "ncbi_taxonomy",
            "num_genes": 36957,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4538,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4538,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:4538",
            "is_a": [
                4527
            ],
            "name": "Oryza glaberrima",
            "namespace": "ncbi_taxonomy",
            "num_genes": 73878,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "African rice"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:17617555"
            ]
        },
        {
            "_id": 4554,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4554,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147428,
                1293361,
                1437183,
                1437197,
                1648036
            ],
            "id": "NCBITaxon:4554",
            "is_a": [
                1293361
            ],
            "name": "Setaria <grass>",
            "namespace": "ncbi_taxonomy",
            "num_genes": 35471,
            "parents": [
                "1293361"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "Setaria"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4555,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4554,
                4555,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147428,
                1293361,
                1437183,
                1437197,
                1648036
            ],
            "id": "NCBITaxon:4555",
            "is_a": [
                4554
            ],
            "name": "Setaria italica",
            "namespace": "ncbi_taxonomy",
            "num_genes": 35471,
            "parents": [
                "4554"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Chaetochloa italica",
                "foxtail millet",
                "Panicum italicum",
                "Setaria viridis subsp. italica"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4557,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4557,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648028,
                1648033
            ],
            "id": "NCBITaxon:4557",
            "is_a": [
                1648028
            ],
            "name": "Sorghum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34567,
            "parents": [
                "1648028"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4564,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4564,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:4564",
            "is_a": [
                1648030
            ],
            "name": "Triticum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 150100,
            "parents": [
                "1648030"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 131567,
            "ancestors": [
                1,
                131567
            ],
            "id": "NCBITaxon:131567",
            "is_a": [
                1
            ],
            "name": "cellular organisms",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1568831,
            "parents": [
                "1"
            ],
            "synonym": [
                "biota"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4558,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4557,
                4558,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648028,
                1648033
            ],
            "id": "NCBITaxon:4558",
            "is_a": [
                4557
            ],
            "name": "Sorghum bicolor",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34567,
            "parents": [
                "4557"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Andropogon sorghum",
                "broomcorn",
                "milo",
                "sorghum",
                "Sorghum bicolor milo",
                "Sorghum bicolor subsp. bicolor",
                "Sorghum nervosum",
                "Sorghum saccharatum",
                "Sorghum vulgare"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4565,
            "alt_id": [
                235075,
                39424
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4564,
                4565,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:4565",
            "is_a": [
                4564
            ],
            "name": "Triticum aestivum",
            "namespace": "ncbi_taxonomy",
            "num_genes": 112496,
            "parents": [
                "4564"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "bread wheat",
                "Canadian hard winter wheat",
                "common wheat",
                "Tricum aestivum",
                "Triticum aestivam",
                "Triticum aestivum subsp. aestivum",
                "Triticum aestivum8",
                "Triticum vulgare",
                "wheat"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4572,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4564,
                4572,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:4572",
            "is_a": [
                4564
            ],
            "name": "Triticum urartu",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37604,
            "parents": [
                "4564"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4575,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4575,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648029,
                1648033
            ],
            "id": "NCBITaxon:4575",
            "is_a": [
                1648029
            ],
            "name": "Zea",
            "namespace": "ncbi_taxonomy",
            "num_genes": 39625,
            "parents": [
                "1648029"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "Euchlaena"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4577,
            "alt_id": [
                4578
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4575,
                4577,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648029,
                1648033
            ],
            "id": "NCBITaxon:4577",
            "is_a": [
                4575
            ],
            "name": "Zea mays",
            "namespace": "ncbi_taxonomy",
            "num_genes": 39625,
            "parents": [
                "4575"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "maize",
                "Zea mays mays",
                "Zea mays var. japonica"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4618,
            "alt_id": [
                40513
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4618,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4618",
            "is_a": [
                4734
            ],
            "name": "Zingiberales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37579,
            "parents": [
                "4734"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Scitamineae",
                "Zingiberiflorae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4637,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4618,
                4637,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4637",
            "is_a": [
                4618
            ],
            "name": "Musaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37579,
            "parents": [
                "4618"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "synonym": [
                "banana family"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4640,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4618,
                4637,
                4640,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4640",
            "is_a": [
                4637
            ],
            "name": "Musa",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37579,
            "parents": [
                "4637"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4641,
            "alt_id": [
                214695
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4618,
                4637,
                4640,
                4641,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4641",
            "is_a": [
                4640
            ],
            "name": "Musa acuminata",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37579,
            "parents": [
                "4640"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "banana",
                "dessert bananas",
                "dwarf banana",
                "Musa AA Group",
                "Musa accumunata",
                "Musa acuminata AA Group",
                "Musa nana",
                "sweet banana"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 4734,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:4734",
            "is_a": [
                1437197
            ],
            "name": "commelinids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 957385,
            "parents": [
                "1437197"
            ],
            "property_value": "has_rank NCBITaxon:subclass",
            "synonym": [
                "Commelinidae",
                "Commeliniflorae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 13332,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                13332,
                22097,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                232365,
                261009
            ],
            "id": "NCBITaxon:13332",
            "is_a": [
                22097
            ],
            "name": "Amborella",
            "namespace": "ncbi_taxonomy",
            "num_genes": 28721,
            "parents": [
                "22097"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 13333,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                13332,
                13333,
                22097,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                232365,
                261009
            ],
            "id": "NCBITaxon:13333",
            "is_a": [
                13332
            ],
            "name": "Amborella trichopoda",
            "namespace": "ncbi_taxonomy",
            "num_genes": 28721,
            "parents": [
                "13332"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 13792,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                13792,
                33090,
                131567,
                1035538
            ],
            "id": "NCBITaxon:13792",
            "is_a": [
                1035538
            ],
            "name": "Mamiellales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "1035538"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 15367,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                15367,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147385,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:15367",
            "is_a": [
                147385
            ],
            "name": "Brachypodium",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26552,
            "parents": [
                "147385"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "false bromes"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 15368,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                15367,
                15368,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147385,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:15368",
            "is_a": [
                15367
            ],
            "name": "Brachypodium distachyon",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26552,
            "parents": [
                "15367"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "annual false brome",
                "false brome",
                "purple false brome",
                "stiff brome"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 22097,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                22097,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                232365,
                261009
            ],
            "id": "NCBITaxon:22097",
            "is_a": [
                261009
            ],
            "name": "Amborellaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 28721,
            "parents": [
                "261009"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 29760,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3602,
                3603,
                29760,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91834,
                131221,
                131567,
                403667,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:29760",
            "is_a": [
                3603
            ],
            "name": "Vitis vinifera",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29971,
            "parents": [
                "3603"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Vitis vinifera subsp. vinifera",
                "wine grape"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 33090,
            "ancestors": [
                1,
                2759,
                33090,
                131567
            ],
            "id": "NCBITaxon:33090",
            "is_a": [
                2759
            ],
            "name": "Viridiplantae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1563725,
            "parents": [
                "2759"
            ],
            "property_value": "has_rank NCBITaxon:kingdom",
            "synonym": [
                "Chlorobionta",
                "Chlorophyta/Embryophyta group",
                "chlorophyte/embryophyte group",
                "green plants",
                "green plants"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 35493,
            "ancestors": [
                1,
                2759,
                33090,
                35493,
                131567
            ],
            "id": "NCBITaxon:35493",
            "is_a": [
                33090
            ],
            "name": "Streptophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1541598,
            "parents": [
                "33090"
            ],
            "property_value": "has_rank NCBITaxon:phylum",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 35711,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                35711,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:35711",
            "is_a": [
                1648021
            ],
            "name": "Leersia",
            "namespace": "ncbi_taxonomy",
            "num_genes": 30615,
            "parents": [
                "1648021"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "synonym": [
                "cutgrass",
                "white grass"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 37682,
            "alt_id": [
                40669,
                4482,
                70688
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4480,
                4734,
                33090,
                35493,
                37682,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:37682",
            "is_a": [
                4480
            ],
            "name": "Aegilops tauschii",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37035,
            "parents": [
                "4480"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Aegilops squarrosa",
                "Aegilops squarrosa auct., non L.",
                "Aegilops squarrosa subsp. squarrosa",
                "Aegilopsm tauschii",
                "Patropyrum tauschii",
                "Patropyrum tauschii subsp. tauschii",
                "Triticum aegilops",
                "Triticum tauschii"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 38820,
            "alt_id": [
                4478,
                4727
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:38820",
            "is_a": [
                4734
            ],
            "name": "Poales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 919806,
            "parents": [
                "4734"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 39946,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4530,
                4734,
                33090,
                35493,
                38820,
                39946,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:39946",
            "is_a": [
                4530
            ],
            "name": "Oryza sativa Indica Group",
            "namespace": "ncbi_taxonomy",
            "num_genes": 88438,
            "parents": [
                "4530"
            ],
            "synonym": [
                "Indian rice",
                "Indica rice",
                "long-grained rice",
                "Oryza sativa (indica cultivar-group)",
                "Oryza sativa (indica group)",
                "Oryza sativa indica",
                "Oryza sativa ssp. indica",
                "Oryza sativa subsp. indica"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:15654106",
                "PMID:17617555",
                "PMID:17907810",
                "PMID:19706531"
            ]
        },
        {
            "_id": 39947,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4530,
                4734,
                33090,
                35493,
                38820,
                39947,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:39947",
            "is_a": [
                4530
            ],
            "name": "Oryza sativa Japonica Group",
            "namespace": "ncbi_taxonomy",
            "num_genes": 91080,
            "parents": [
                "4530"
            ],
            "synonym": [
                "Japanese rice",
                "Japonica rice",
                "Oryza sativa (japonica culticar-group)",
                "Oryza sativa (japonica cultivar-group)",
                "Oryza sativa japonica",
                "Oryza sativa subsp. japonica"
            ],
            "xref": [
                "GC_ID:1",
                "PMID:15654106",
                "PMID:17617555",
                "PMID:17907810",
                "PMID:19706531"
            ]
        },
        {
            "_id": 40148,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4734,
                33090,
                35493,
                38820,
                40148,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:40148",
            "is_a": [
                4527
            ],
            "name": "Oryza glumipatula",
            "namespace": "ncbi_taxonomy",
            "num_genes": 39018,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Oryza glumaepatula"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 40149,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4734,
                33090,
                35493,
                38820,
                40149,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:40149",
            "is_a": [
                4527
            ],
            "name": "Oryza meridionalis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 31591,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Oryza meridionales"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 41938,
            "alt_id": [
                3628
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                41938,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:41938",
            "is_a": [
                91836
            ],
            "name": "Malvales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29188,
            "parents": [
                "91836"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "synonym": [
                "Malvanae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 45156,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                45156,
                131567,
                265316,
                265318
            ],
            "id": "NCBITaxon:45156",
            "is_a": [
                265316
            ],
            "name": "Cyanidioschyzon",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "265316"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 45157,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                45156,
                45157,
                131567,
                265316,
                265318
            ],
            "id": "NCBITaxon:45157",
            "is_a": [
                45156
            ],
            "name": "Cyanidioschyzon merolae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "45156"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 49274,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                4107,
                33090,
                35493,
                49274,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                424574,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:49274",
            "is_a": [
                4107
            ],
            "name": "Lycopersicon",
            "namespace": "ncbi_taxonomy",
            "num_genes": 38735,
            "parents": [
                "4107"
            ],
            "property_value": "has_rank NCBITaxon:subgenus",
            "synonym": [
                "Lycopersicon (Mill.) Seithe",
                "Lycopersicum"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 51351,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3705,
                3711,
                33090,
                35493,
                51351,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:51351",
            "is_a": [
                3711
            ],
            "name": "Brassica rapa subsp. pekinensis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 42846,
            "parents": [
                "3711"
            ],
            "property_value": "has_rank NCBITaxon:subspecies",
            "synonym": [
                "bai cai",
                "Brassica campestris (Pekinensis Group)",
                "Brassica campestris ssp. pekinensis",
                "Brassica campestris subsp. pekinensis",
                "Brassica campestris var. pekinensis",
                "Brassica pekinensis",
                "Brassica rapa Chinese Cabbage Group",
                "Brassica rapa ssp. pekinensis",
                "Brassica rapa var. glabra",
                "celery cabbage",
                "Chinese cabbage",
                "pe-tsai"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 58023,
            "ancestors": [
                1,
                2759,
                3193,
                33090,
                35493,
                58023,
                131221,
                131567
            ],
            "id": "NCBITaxon:58023",
            "is_a": [
                3193
            ],
            "name": "Tracheophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1509325,
            "parents": [
                "3193"
            ],
            "synonym": [
                "vascular plants",
                "vascular plants"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 58024,
            "ancestors": [
                1,
                2759,
                3193,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567
            ],
            "id": "NCBITaxon:58024",
            "is_a": [
                78536
            ],
            "name": "Spermatophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1474437,
            "parents": [
                "78536"
            ],
            "synonym": [
                "seed plants",
                "seed plants"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 59689,
            "alt_id": [
                63679
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3701,
                33090,
                35493,
                58023,
                58024,
                59689,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                980083,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:59689",
            "is_a": [
                3701
            ],
            "name": "Arabidopsis lyrata",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32667,
            "parents": [
                "3701"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "lyrate rockcress"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 65489,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4527,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                65489,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:65489",
            "is_a": [
                4527
            ],
            "name": "Oryza barthii",
            "namespace": "ncbi_taxonomy",
            "num_genes": 36899,
            "parents": [
                "4527"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "African wild rice",
                "Oryza breviligulata"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 70447,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                13792,
                33090,
                70447,
                131567,
                1035538,
                1525212
            ],
            "id": "NCBITaxon:70447",
            "is_a": [
                1525212
            ],
            "name": "Ostreococcus",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "1525212"
            ],
            "property_value": "has_rank NCBITaxon:genus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 71240,
            "alt_id": [
                3399
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                78536,
                131221,
                131567,
                1437183
            ],
            "id": "NCBITaxon:71240",
            "is_a": [
                1437183
            ],
            "name": "eudicotyledons",
            "namespace": "ncbi_taxonomy",
            "num_genes": 488331,
            "parents": [
                "1437183"
            ],
            "synonym": [
                "dicots",
                "Dicotyledoneae",
                "dicotyledons",
                "eudicots",
                "Magnoliopsida"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 71274,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:71274",
            "is_a": [
                1437201
            ],
            "name": "asterids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "1437201"
            ],
            "property_value": "has_rank NCBITaxon:subclass",
            "synonym": [
                "Asteridae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 71275,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:71275",
            "is_a": [
                1437201
            ],
            "name": "rosids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 406622,
            "parents": [
                "1437201"
            ],
            "property_value": "has_rank NCBITaxon:subclass",
            "synonym": [
                "Rosidae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 72025,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:72025",
            "is_a": [
                91835
            ],
            "name": "Fabales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 108247,
            "parents": [
                "91835"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 77586,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                35711,
                38820,
                58023,
                58024,
                77586,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:77586",
            "is_a": [
                35711
            ],
            "name": "Leersia perrieri",
            "namespace": "ncbi_taxonomy",
            "num_genes": 30615,
            "parents": [
                "35711"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 78536,
            "ancestors": [
                1,
                2759,
                3193,
                33090,
                35493,
                58023,
                78536,
                131221,
                131567
            ],
            "id": "NCBITaxon:78536",
            "is_a": [
                58023
            ],
            "name": "Euphyllophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1474437,
            "parents": [
                "58023"
            ],
            "synonym": [
                "euphyllophytes"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 81972,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3701,
                33090,
                35493,
                58023,
                58024,
                59689,
                71240,
                71275,
                78536,
                81972,
                91827,
                91836,
                131221,
                131567,
                980083,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:81972",
            "is_a": [
                59689
            ],
            "name": "Arabidopsis lyrata subsp. lyrata",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32667,
            "parents": [
                "59689"
            ],
            "property_value": "has_rank NCBITaxon:subspecies",
            "synonym": [
                "Arabis lyrata",
                "Arabis lyrata subsp. lyrata",
                "Cardaminopsis lyrata"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 88036,
            "ancestors": [
                1,
                2759,
                3193,
                3244,
                3245,
                3246,
                33090,
                35493,
                58023,
                88036,
                131221,
                131567,
                1521260
            ],
            "id": "NCBITaxon:88036",
            "is_a": [
                3246
            ],
            "name": "Selaginella moellendorffii",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34888,
            "parents": [
                "3246"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Selaginella moellendorfii"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 91827,
            "alt_id": [
                1437200
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                78536,
                91827,
                131221,
                131567,
                1437183
            ],
            "id": "NCBITaxon:91827",
            "is_a": [
                71240
            ],
            "name": "Gunneridae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 488331,
            "parents": [
                "71240"
            ],
            "synonym": [
                "core eudicots",
                "core eudicotyledons"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 91834,
            "alt_id": [
                72024
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91834,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:91834",
            "is_a": [
                71275
            ],
            "name": "rosids incertae sedis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29971,
            "parents": [
                "71275"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 91835,
            "alt_id": [
                41939,
                4293
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:91835",
            "is_a": [
                71275
            ],
            "name": "fabids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 179123,
            "parents": [
                "71275"
            ],
            "synonym": [
                "eurosids I"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 91836,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:91836",
            "is_a": [
                71275
            ],
            "name": "malvids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 197528,
            "parents": [
                "71275"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 91888,
            "alt_id": [
                41935
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:91888",
            "is_a": [
                71274
            ],
            "name": "lamiids",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "71274"
            ],
            "synonym": [
                "euasterids I"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 109376,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                3705,
                3712,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                109376,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:109376",
            "is_a": [
                3712
            ],
            "name": "Brassica oleracea var. oleracea",
            "namespace": "ncbi_taxonomy",
            "num_genes": 59225,
            "parents": [
                "3712"
            ],
            "property_value": "has_rank NCBITaxon:varietas",
            "synonym": [
                "Brassica oleracea subsp. oleracea"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 112509,
            "alt_id": [
                4514
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4512,
                4513,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                112509,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648017,
                1648038
            ],
            "id": "NCBITaxon:112509",
            "is_a": [
                4513
            ],
            "name": "Hordeum vulgare subsp. vulgare",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26067,
            "parents": [
                "4513"
            ],
            "property_value": "has_rank NCBITaxon:subspecies",
            "synonym": [
                "barley",
                "domesticated barley",
                "Hordeum sativum",
                "Hordeum vulgare var. nudum",
                "Hordeum vulgare var. vulgare",
                "two-rowed barley"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 114656,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                3214,
                33090,
                35493,
                114656,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:114656",
            "is_a": [
                3214
            ],
            "name": "Funariidae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3214"
            ],
            "property_value": "has_rank NCBITaxon:subclass",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 131221,
            "ancestors": [
                1,
                2759,
                33090,
                35493,
                131221,
                131567
            ],
            "id": "NCBITaxon:131221",
            "is_a": [
                35493
            ],
            "name": "Streptophytina",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1541598,
            "parents": [
                "35493"
            ],
            "synonym": [
                "Charophyta/Embryophyta group",
                "charophyte/embryophyte group"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147367,
            "alt_id": [
                1647564
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147367",
            "is_a": [
                359160
            ],
            "name": "Oryzoideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 570389,
            "parents": [
                "359160"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "synonym": [
                "Ehrhartoideae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147368,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147368",
            "is_a": [
                359160
            ],
            "name": "Pooideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 239754,
            "parents": [
                "359160"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147369,
            "alt_id": [
                219388
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147369",
            "is_a": [
                147370
            ],
            "name": "Panicoideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 109663,
            "parents": [
                "147370"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "synonym": [
                "Centothecoideae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147370,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147370,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147370",
            "is_a": [
                4479
            ],
            "name": "PACMAD clade",
            "namespace": "ncbi_taxonomy",
            "num_genes": 109663,
            "parents": [
                "4479"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147380,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147380",
            "is_a": [
                147367
            ],
            "name": "Oryzeae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 570389,
            "parents": [
                "147367"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147385,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147385,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:147385",
            "is_a": [
                147368
            ],
            "name": "Brachypodieae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26552,
            "parents": [
                "147368"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147389,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648038
            ],
            "id": "NCBITaxon:147389",
            "is_a": [
                1648038
            ],
            "name": "Triticeae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 213202,
            "parents": [
                "1648038"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147428,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147428,
                1437183,
                1437197,
                1648036
            ],
            "id": "NCBITaxon:147428",
            "is_a": [
                1648036
            ],
            "name": "Paniceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 35471,
            "parents": [
                "1648036"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 147429,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648033
            ],
            "id": "NCBITaxon:147429",
            "is_a": [
                1648033
            ],
            "name": "Andropogoneae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 74192,
            "parents": [
                "1648033"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 163735,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163735,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:163735",
            "is_a": [
                3814
            ],
            "name": "Phaseoleae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54174,
            "parents": [
                "3814"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 163742,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163742,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:163742",
            "is_a": [
                3814
            ],
            "name": "Trifolieae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54073,
            "parents": [
                "3814"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 171637,
            "alt_id": [
                171636,
                721786
            ],
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                3745,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                171637,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:171637",
            "is_a": [
                3745
            ],
            "name": "Maloideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "3745"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "synonym": [
                "Amygdaloideae",
                "Spiraeoideae"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 214687,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4618,
                4637,
                4640,
                4641,
                4734,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                214687,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:214687",
            "is_a": [
                4641
            ],
            "name": "Musa acuminata subsp. malaccensis",
            "namespace": "ncbi_taxonomy",
            "num_genes": 37579,
            "parents": [
                "4641"
            ],
            "property_value": "has_rank NCBITaxon:subspecies",
            "synonym": [
                "Musa acuminata var. malaccensis",
                "pisang utan",
                "true apple banana",
                "wild Malaysian banana"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 214909,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3629,
                33090,
                35493,
                41938,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                214909,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:214909",
            "is_a": [
                3629
            ],
            "name": "Byttnerioideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29188,
            "parents": [
                "3629"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 232365,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                232365
            ],
            "id": "NCBITaxon:232365",
            "is_a": [
                3398
            ],
            "name": "basal Magnoliophyta",
            "namespace": "ncbi_taxonomy",
            "num_genes": 28721,
            "parents": [
                "3398"
            ],
            "synonym": [
                "stem Magnoliophyta"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 238069,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3646,
                3688,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                238069,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:238069",
            "is_a": [
                3688
            ],
            "name": "Saliceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 41377,
            "parents": [
                "3688"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 242159,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                13792,
                33090,
                70447,
                131567,
                242159,
                1035538,
                1525212
            ],
            "id": "NCBITaxon:242159",
            "is_a": [
                70447
            ],
            "name": "Ostreococcus 'lucimarinus'",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "70447"
            ],
            "property_value": "has_rank NCBITaxon:species",
            "synonym": [
                "Ostreococcus lucimarinus",
                "Ostreococcus sp. CCE9901"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 261009,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                232365,
                261009
            ],
            "id": "NCBITaxon:261009",
            "is_a": [
                232365
            ],
            "name": "Amborellales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 28721,
            "parents": [
                "232365"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 265316,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                131567,
                265316,
                265318
            ],
            "id": "NCBITaxon:265316",
            "is_a": [
                265318
            ],
            "name": "Cyanidiaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "265318"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 265318,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                131567,
                265318
            ],
            "id": "NCBITaxon:265318",
            "is_a": [
                2797
            ],
            "name": "Cyanidiales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "2797"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 280699,
            "ancestors": [
                1,
                2759,
                2763,
                2797,
                45156,
                45157,
                131567,
                265316,
                265318,
                280699
            ],
            "id": "NCBITaxon:280699",
            "is_a": [
                45157
            ],
            "name": "Cyanidioschyzon merolae strain 10D",
            "namespace": "ncbi_taxonomy",
            "num_genes": 5106,
            "parents": [
                "45157"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 359160,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                359160,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:359160",
            "is_a": [
                4479
            ],
            "name": "BOP clade",
            "namespace": "ncbi_taxonomy",
            "num_genes": 810143,
            "parents": [
                "4479"
            ],
            "synonym": [
                "BEP clade"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 403667,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91834,
                131221,
                131567,
                403667,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:403667",
            "is_a": [
                91834
            ],
            "name": "Vitales",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29971,
            "parents": [
                "91834"
            ],
            "property_value": "has_rank NCBITaxon:order",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 404260,
            "ancestors": [
                1,
                2759,
                3193,
                3208,
                33090,
                35493,
                131221,
                131567,
                404260
            ],
            "id": "NCBITaxon:404260",
            "is_a": [
                3208
            ],
            "name": "Bryophytina",
            "namespace": "ncbi_taxonomy",
            "num_genes": 32273,
            "parents": [
                "3208"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 424551,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:424551",
            "is_a": [
                4070
            ],
            "name": "Solanoideae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "4070"
            ],
            "property_value": "has_rank NCBITaxon:subfamily",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 424574,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4069,
                4070,
                33090,
                35493,
                58023,
                58024,
                71240,
                71274,
                78536,
                91827,
                91888,
                131221,
                131567,
                424551,
                424574,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:424574",
            "is_a": [
                424551
            ],
            "name": "Solaneae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 81709,
            "parents": [
                "424551"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 436017,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                13792,
                33090,
                70447,
                131567,
                242159,
                436017,
                1035538,
                1525212
            ],
            "id": "NCBITaxon:436017",
            "is_a": [
                242159
            ],
            "name": "Ostreococcus lucimarinus CCE9901",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "242159"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 721805,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3744,
                3745,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91835,
                131221,
                131567,
                171637,
                721805,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:721805",
            "is_a": [
                171637
            ],
            "name": "Amygdaleae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 29499,
            "parents": [
                "171637"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 980083,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                980083,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:980083",
            "is_a": [
                3700
            ],
            "name": "Camelineae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 66269,
            "parents": [
                "3700"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 981071,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3699,
                3700,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                78536,
                91827,
                91836,
                131221,
                131567,
                981071,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:981071",
            "is_a": [
                3700
            ],
            "name": "Brassiceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 102071,
            "parents": [
                "3700"
            ],
            "property_value": "has_rank NCBITaxon:tribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1035538,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                33090,
                131567,
                1035538
            ],
            "id": "NCBITaxon:1035538",
            "is_a": [
                3152
            ],
            "name": "Mamiellophyceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "3152"
            ],
            "property_value": "has_rank NCBITaxon:class",
            "xref": [
                "GC_ID:1",
                "PMID:23020233"
            ]
        },
        {
            "_id": 1293361,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147428,
                1293361,
                1437183,
                1437197,
                1648036
            ],
            "id": "NCBITaxon:1293361",
            "is_a": [
                147428
            ],
            "name": "Cenchrinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 35471,
            "parents": [
                "147428"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1437183,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183
            ],
            "id": "NCBITaxon:1437183",
            "is_a": [
                3398
            ],
            "name": "Mesangiospermae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 1445716,
            "parents": [
                "3398"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1437197,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                33090,
                35493,
                58023,
                58024,
                78536,
                131221,
                131567,
                1437183,
                1437197
            ],
            "id": "NCBITaxon:1437197",
            "is_a": [
                4447
            ],
            "name": "Petrosaviidae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 957385,
            "parents": [
                "4447"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1437201,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                33090,
                35493,
                58023,
                58024,
                71240,
                78536,
                91827,
                131221,
                131567,
                1437183,
                1437201
            ],
            "id": "NCBITaxon:1437201",
            "is_a": [
                91827
            ],
            "name": "Pentapetalae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 488331,
            "parents": [
                "91827"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1462606,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                3803,
                3814,
                3846,
                33090,
                35493,
                58023,
                58024,
                71240,
                71275,
                72025,
                78536,
                91827,
                91835,
                131221,
                131567,
                163735,
                1437183,
                1437201,
                1462606
            ],
            "id": "NCBITaxon:1462606",
            "is_a": [
                3846
            ],
            "name": "Soja",
            "namespace": "ncbi_taxonomy",
            "num_genes": 54174,
            "parents": [
                "3846"
            ],
            "property_value": "has_rank NCBITaxon:subgenus",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1521260,
            "ancestors": [
                1,
                2759,
                3193,
                33090,
                35493,
                58023,
                131221,
                131567,
                1521260
            ],
            "id": "NCBITaxon:1521260",
            "is_a": [
                58023
            ],
            "name": "Lycopodiidae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34888,
            "parents": [
                "58023"
            ],
            "property_value": "has_rank NCBITaxon:subclass",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1525212,
            "ancestors": [
                1,
                2759,
                3041,
                3152,
                13792,
                33090,
                131567,
                1035538,
                1525212
            ],
            "id": "NCBITaxon:1525212",
            "is_a": [
                13792
            ],
            "name": "Bathycoccaceae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 7640,
            "parents": [
                "13792"
            ],
            "property_value": "has_rank NCBITaxon:family",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648017,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648017,
                1648038
            ],
            "id": "NCBITaxon:1648017",
            "is_a": [
                147389
            ],
            "name": "Hordeinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 26067,
            "parents": [
                "147389"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648021,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147367,
                147380,
                359160,
                1437183,
                1437197,
                1648021
            ],
            "id": "NCBITaxon:1648021",
            "is_a": [
                147380
            ],
            "name": "Oryzinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 570389,
            "parents": [
                "147380"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648028,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648028,
                1648033
            ],
            "id": "NCBITaxon:1648028",
            "is_a": [
                147429
            ],
            "name": "Sorghinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 34567,
            "parents": [
                "147429"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648029,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                147429,
                1437183,
                1437197,
                1648029,
                1648033
            ],
            "id": "NCBITaxon:1648029",
            "is_a": [
                147429
            ],
            "name": "Tripsacinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 39625,
            "parents": [
                "147429"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648030,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                147389,
                359160,
                1437183,
                1437197,
                1648030,
                1648038
            ],
            "id": "NCBITaxon:1648030",
            "is_a": [
                147389
            ],
            "name": "Triticinae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 187135,
            "parents": [
                "147389"
            ],
            "property_value": "has_rank NCBITaxon:subtribe",
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648033,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                1437183,
                1437197,
                1648033
            ],
            "id": "NCBITaxon:1648033",
            "is_a": [
                147369
            ],
            "name": "Andropogonodae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 74192,
            "parents": [
                "147369"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648036,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147369,
                147370,
                1437183,
                1437197,
                1648036
            ],
            "id": "NCBITaxon:1648036",
            "is_a": [
                147369
            ],
            "name": "Panicodae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 35471,
            "parents": [
                "147369"
            ],
            "xref": [
                "GC_ID:1"
            ]
        },
        {
            "_id": 1648038,
            "ancestors": [
                1,
                2759,
                3193,
                3398,
                4447,
                4479,
                4734,
                33090,
                35493,
                38820,
                58023,
                58024,
                78536,
                131221,
                131567,
                147368,
                359160,
                1437183,
                1437197,
                1648038
            ],
            "id": "NCBITaxon:1648038",
            "is_a": [
                147368
            ],
            "name": "Triticodae",
            "namespace": "ncbi_taxonomy",
            "num_genes": 213202,
            "parents": [
                "147368"
            ],
            "xref": [
                "GC_ID:1"
            ]
        }
    ]
}
