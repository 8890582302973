var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

import React, { Component } from 'react';
import remarkGfm from 'remark-gfm';
import { Remark } from 'react-remark';
import remarkHeading from 'remark-heading-id';
import imgLinks from '@pondorasti/remark-img-links';
import { Table } from 'react-bootstrap';
import SidebarMenu from 'react-bootstrap-sidebar-menu';
import "../css/styles.css";
import "../css/mdview.css";

var find_and_scroll = function find_and_scroll(eventKey) {
  if (eventKey) {
    var el = document.getElementById('mdView-' + eventKey);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
      var nextURL = '' + window.location.origin + window.location.pathname + '?section=' + eventKey;
      var nextTitle = window.location.host + ' section ' + eventKey;
      var nextState = { additionalInformation: 'updated the URL based on click' };
      window.history.pushState(nextState, nextTitle, nextURL);
      return true;
    }
  }
  return false;
};

var _default = function (_Component) {
  _inherits(_default, _Component);

  function _default(props) {
    _classCallCheck(this, _default);

    var _this = _possibleConstructorReturn(this, _Component.call(this, props));

    _this.state = {
      files: [],
      content: {},
      loading: 0,
      started: Date.now()
    };
    return _this;
  }

  _default.prototype.getFiles = function getFiles(path) {
    var md_regex = /---[0-9][0-9][0-9][0-9]-[0-9][0-9]-[0-9][0-9]/i; //\.md$/i;
    var url = 'https://api.github.com/repos/' + this.props.org + '/' + this.props.repo + '/contents/' + path;
    var now = this.props.date ? new Date(this.props.date) : new Date();
    return fetch(url).then(function (response) {
      return response.json();
    }).then(function (data) {
      return data.filter(function (f) {
        return md_regex.test(f.name);
      }).map(function (f) {
        f.name = f.name.replace(/\.md$/, "");

        var _f$name$split = f.name.split('---'),
            name = _f$name$split[0],
            datestr = _f$name$split[1];

        f.name = name;
        f.date = new Date(datestr);
        f.fetched = false;
        f.parent = path;
        f.id = f.path.replace(/.*?\//, '').replace(/---\d+-\d+-\d+/g, '').replace(/\.md$/, '');
        return f;
      }).filter(function (f) {
        return f.date < now;
      }).sort(function (a, b) {
        if (a.date < b.date) {
          return 1;
        }
        if (a.date > b.date) {
          return -1;
        }
        return 0;
      });
    });
  };

  _default.prototype.getContent = function getContent() {
    var _this2 = this;

    var files = this.state.files;
    var n_to_load = 0;
    files.forEach(function (f) {
      if (f.download_url && !f.fetched && !_this2.state.content.hasOwnProperty(f.sha)) {
        n_to_load++;
        f.fetched = true;
        fetch(f.download_url).then(function (response) {
          return response.text();
        }).then(function (text) {
          var content = _this2.state.content;
          if (!content.hasOwnProperty(f.sha)) {
            content[f.sha] = text;
            _this2.setState({ content: content, loading: _this2.state.loading - 1 });
          } else {
            console.log(f.sha, 'already loaded');
          }
        });
      }
      if (f.type === "dir" && !f.fetched) {
        f.fetched = true;
        _this2.getFiles(f.path).then(function (children) {
          if (children) {
            var _files = _this2.state.files.concat(children);
            _this2.setState({ files: _files, loading: _this2.state.loading + children.length - 1 });
          }
        });
      }
    });
    if (n_to_load > 0) {
      this.setState({ files: files });
    }
  };

  _default.prototype.componentDidMount = function componentDidMount() {
    var _this3 = this;

    var queryString = window.location.search;
    var params = new URLSearchParams(queryString);
    var section = params.get('section');
    this.getFiles(this.props.path).then(function (files) {
      _this3.setState({ files: files, currentPos: section, loading: files.length });
    });
  };

  _default.prototype.componentDidUpdate = function componentDidUpdate() {
    var _this4 = this;

    this.getContent();
    if (this.state.loading === 0 && this.state.currentPos) {
      // give the page a moment to layout before scrolling
      var load_time = Date.now() - this.state.started;
      setTimeout(function () {
        return find_and_scroll(_this4.state.currentPos);
      }, 2 * load_time);
      this.setState({ loading: -1 });
    }
  };

  _default.prototype.renderItems = function renderItems(path, prefix) {
    var _this5 = this;

    return React.createElement(
      SidebarMenu.Nav,
      null,
      ' ',
      this.state.files.filter(function (f) {
        return f.parent === path;
      }).map(function (f, i) {
        var fileId = prefix.concat([i + 1]).join('.');
        if (f.type === 'file') {
          return React.createElement(
            SidebarMenu.Nav.Link,
            { eventKey: f.id, key: i },
            React.createElement(
              'span',
              { style: { paddingLeft: prefix.length * 35 + 'px' } },
              React.createElement(
                SidebarMenu.Nav.Icon,
                null,
                '\u2022'
              ),
              React.createElement(
                SidebarMenu.Nav.Title,
                null,
                f.name
              )
            )
          );
        }
        if (f.type === 'dir') {
          return React.createElement(
            SidebarMenu.Sub,
            { eventKey: f.id, key: i, defaultExpanded: true },
            React.createElement(
              SidebarMenu.Sub.Toggle,
              null,
              React.createElement(SidebarMenu.Nav.Icon, null),
              React.createElement(
                SidebarMenu.Nav.Title,
                null,
                f.name
              )
            ),
            React.createElement(
              SidebarMenu.Sub.Collapse,
              null,
              _this5.renderItems(f.path, prefix.concat([i + 1]))
            )
          );
        }
      })
    );
  };

  _default.prototype.renderContent = function renderContent(path, prefix) {
    var _this6 = this;

    return this.state.files.filter(function (f) {
      return f.parent === path;
    }).map(function (f, i) {
      var fileId = prefix.concat([i + 1]).join('.');
      if (f.type === 'file') {
        var content = _this6.state.content[f.sha];
        return React.createElement(
          'div',
          null,
          React.createElement(
            'h5',
            { className: 'mdview-body-section', id: 'mdView-' + f.id },
            f.name
          ),
          ' ',
          content && React.createElement(
            Remark,
            {
              ref: _this6.docRef,
              remarkPlugins: [remarkGfm, remarkHeading, [imgLinks, { absolutePath: 'https://github.com/' + _this6.props.org + '/' + _this6.props.repo + '/raw/main/' + _this6.props.path + '/' }]],
              rehypeReactOptions: {
                components: {
                  table: function table(props) {
                    return React.createElement(Table, _extends({ size: 'sm', striped: true, bordered: true, hover: true }, props));
                  }
                }
              }
            },
            content
          )
        );
      } else {
        return React.createElement(
          'div',
          null,
          React.createElement(
            'h4',
            { className: 'mdview-body-section', id: f.id },
            f.name
          ),
          _this6.renderContent(f.path, prefix.concat([i + 1]))
        );
      }
    });
  };

  _default.prototype.renderSidebar = function renderSidebar() {
    var _this7 = this;

    return React.createElement(
      SidebarMenu,
      {
        variant: 'dark',
        bg: 'dark',
        expand: 'lg',
        hide: 'md',
        onSelect: function onSelect(eventKey) {
          if (find_and_scroll(eventKey) && eventKey !== _this7.state.currentPos) _this7.setState({ currentPos: eventKey });
        }
      },
      React.createElement(
        SidebarMenu.Collapse,
        null,
        React.createElement(
          SidebarMenu.Header,
          null,
          React.createElement(
            SidebarMenu.Brand,
            { title: this.props.heading || 'Docs' },
            this.props.heading || 'Docs'
          )
        ),
        React.createElement(
          SidebarMenu.Body,
          null,
          this.renderItems(this.props.path, [])
        )
      )
    );
  };

  _default.prototype.sidebar = function sidebar() {
    return React.createElement(
      'section',
      { className: 'mdview-sidebar', style: { height: 'calc(100vh - ' + (this.props.offset || 0) + 'px)' } },
      this.renderSidebar()
    );
  };

  _default.prototype.content = function content() {
    return React.createElement(
      'section',
      { className: 'mdview-body' },
      React.createElement(
        'div',
        { className: 'mdview-body-wrapper' },
        this.renderContent(this.props.path, [])
      )
    );
  };

  _default.prototype.render = function render() {
    return React.createElement(
      'div',
      { className: 'mdview-container', style: { gridTemplateRows: 'calc(100vh - ' + (this.props.offset || 0) + 'px)' } },
      this.sidebar(),
      this.content()
    );
  };

  return _default;
}(Component);

export { _default as default };